.Common{
    background:radial-gradient(#e3f5de,#6ffa4c);
}

.Rare{
    background:radial-gradient(#def0f7,#50e0fa);
}

.Epic{
    background:radial-gradient(#e1c4f5,#a751f8);
}

.Mystic{
    background:radial-gradient(#f7dfcb,#fc8d43);
}

.Legendary{
    background:radial-gradient(#f3c5c5,#ff6d6d);
}

.gold{
    color:gold
}

.text-orange{
    color:#F27B05
}

.text-yellow{
    color: rgb(248, 202, 51)
}

.text-green{
    color: rgb(18, 160, 18)
}

.text-blue{
    color: rgb(102, 189, 211)
}

.text-red{
    color: rgb(206, 59, 96)
}

.picker{
    color:rgb(182, 182, 182);
    background: linear-gradient(#332500, #cac3ae, #332500)
}
.play-bg{
    background: radial-gradient(#927a77 0%, #3e2723 100%);
    color: white;
}