
.work-container{
  padding-left:50px;
  padding-right:40px;
  margin-top:30px
}

.work-container-small{
  padding:20px;
  border-radius: 15px;
  width: 100%;
  background-color: white;
  border: groove;
  margin-bottom: 30px;
}

.quest-card{
  border-radius: 15px;
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin-bottom: 15px;
  background-color: rgb(250,250,250);
}

.quest-container{
  padding: 13px;
  text-align: left;
  background-color: white;
  border-radius: 15px;
  border: groove;
}

.notbig{
  font-size:18px;
  word-break: break-word;
}

.topbar{
  position: fixed;
  width: 100vw;
  max-width: 100vw;
  min-height: 50px;
  background-color: #3e2723;
  z-index: 2;
  color: white;
  padding:3px;
}

.nav-modal{
  width: 200px;
  height: 100vh;
  background-color: #3e2723;
  padding: 3px;
  color: white;
}

.nav-modal-back{
  position: fixed;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 1;
  display: none;
}

.sidebar{
  position: fixed;
  height: 100vh;
  width: 50px;
  background-color: #3e2723;
  z-index: 2;
  color: white;
}

.hamburg{
  opacity: 0;
}

.inside-container{
  margin-left:50px;
  padding-bottom: 50px
}

.card-item{
  font-size: 15px;
  width: 250px;
}

.resize-font{
  font-size: 15px;
}

.notbig{
  font-size:18px;
  word-break: break-word;
}

.card-quest{
  max-width: 500px;
  margin: 0 auto;
  min-width: 310px;
  padding: 5px 25px;
}

.chat-container{
  position: fixed;
  right: 0%;
  top: 0%;
  max-width: 450px;
  z-index: 1;
}

.chat-container2{
  overflow: auto;
  width: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #3e2723;
  overflow-x: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.8);
}

.spacer{
  height:45px;
}
.spacer-chat{
  height:50px;
}

@media screen and (max-width: 1600px) {
  .work-container {
    padding-left:40px;
    padding-right:20px
  }
}

@media screen and (max-width: 1200px) {
  .work-container {
    padding-left:20px;
    padding-right:7px
  }
  .sidebar{
    display: none;
  }
  .inside-container{
    margin: 0
  }
  .hamburg{
    opacity: 1;
    color: rgb(207, 207, 207);
    font-size: 20px;
    cursor: pointer;
  }
  .nav-modal-back{
    display: inline;
  }
}
@media screen and (max-width: 575px) {
  .resize-h1{
    font-size: 30px;
  }
  .topbar{
    z-index: 1;
  }
  .spacer{
    height:70px
  }
  .spacer-chat{
    height: 0px
  }
  .work-container {
    padding-left:4px;
    padding-right:7px
  }
  .auto-size{
    font-size: 20px;
  }
  .card-item{
    font-size: 10px;
    width: 100px;
  }
  .resize-font{
    font-size: 1px;
    padding: 1px;
  }
  .notbig{
    font-size: 14px;
  }
  .card-quest{
    padding: 5px;
  }
}
@media screen and (max-width: 380px) {
  .resize-h1{
    font-size: 25px;
  }
}

@media screen and (max-width: 340px) {
  .notbig{
    font-size: 12px;
  }
}