.checkmark{
    cursor:pointer;
    position: absolute;
    font-weight: bolder;
    font-size: 17px
}
.bgc-green{
    color: white;
    background-color: rgb(81, 162, 194); 
}
.bgc-blue{
    color: rgb(0, 0, 0);
    background-color: rgb(102, 189, 211); 
}
.btn{
    border: none;
    border-radius: 23px;
    padding: 7px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 19px;
    box-shadow: 0px 1px 3px 3px rgba(0, 0, 0, 0.1);
    font-weight: 750;
}
.btn-hover:hover{
    background-color:rgb(233, 233, 233);
    color: rgb(0, 0, 0);
}

.btn-green{
    background-color:rgb(8, 189, 150);
    color:rgb(255, 255, 255);
    outline: 0;
    font-weight: bolder;
    border:0;
    width:100%;
    font-size:23px;
    padding: 6px 10px;
    border-radius: 6px;
    
}
.btn-green:hover{
    background-color:rgb(255, 255, 255);
    color: rgb(60, 60, 60);
}

.customLink:link, .customLink:visited {
color: white;
padding: 5px 10px;
text-align: center;
text-decoration: none;
display: inline-block;
border-style: solid;
width:100%;
font-size:23px;
font-weight: bolder;
border-radius: 6px;
}

.customLink:hover, .customLink:active {
color: rgb(59, 59, 59);
border-color: white;
background-color: white;
}

.customLink2, .customLink2:link, .customLink2:visited {
color: white;
text-align: center;
display: inline-block;
width:100%;
font-size:20px;
cursor: pointer;
}

.customLink2:hover, .customLink2:active {
    color: rgb(209, 209, 209);
}

button:focus {outline:0}