h1, h2, h3, h4{
  font-weight: bolder;
}

.hoverimage:hover{
  cursor: pointer;
  filter: brightness(80%);
}

.hoverbadge:hover{
  color: gray;
  cursor: pointer;
}

.hoverbadge{
  color: rgb(65, 65, 65);
}

.container-round{
  background-color: rgb(250, 250, 250);
  max-width: 300px;
  border-radius: 20px;
  margin: 10px auto;
  font-size: 60px;
  padding-top:5px;
  border: solid;
}

.container-round-small{
  background-color: rgb(250, 250, 250);
  max-width: 170px;
  border-radius: 10px;
  margin: 2px auto;
  padding-top:2px;
  border: 2px solid;
}

.main{
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
  background-color: rgb(235, 235, 235);
}

.gradientBG{
  background:linear-gradient(0.38turn,#ff821c,#ff9419, #ffb617);
}

label {
  color:rgb(156, 156, 156);
  position: absolute;
  left: 0%;
  transform: translate(0, 16px) scale(1);
  transition: all .1s ease-in-out;
  padding-left: 14px;
  pointer-events:none;
}

.hint{
  color:rgb(131, 131, 131);
  position: absolute;
  right:2%;
  top:30%;
  cursor: pointer;
}

.hint:hover{
  color:rgb(184, 184, 184);
}

input{
  border-radius: 15px;
  padding: 10px;
  width:100%;
}

a:link {
  text-decoration: none;
}

.bolder{
  font-size: 22px;
  text-align: center;
  margin: 10px 0;
  font-weight: bolder;
}

.bolderr{
  font-weight: 500;
}

.colorpicker{
  color: rgb(255, 199, 77)
}

.chat-block{
  cursor: pointer;
}

.chat-block:hover{
  background-color: #5c3b35;
}

.chat-button{
  background-color: #534e43;
  border-top-left-radius: 18px;
  width: 100%;
}

.chat-button:hover{
  background-color: #68614e;
}

.tab{
  padding-top: 10px;
  padding-bottom: 6px;
  cursor: pointer;
  background-color: rgb(240, 184, 0);
  color: rgba(0,0,0,0.5)
}
.tab:hover{
  color: black
}

.card-round{
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.card-top{
  background-color: rgb(240, 164, 0);
  color: white;
  font-size: 20px;
  padding: 10px 30px;
  font-weight: bolder;
  text-align: left;
}

.card-bottom{
  background-color: rgba(255,255,255,1);
  padding: 10px 25px;
}
.card-game{
  background-color: rgb(241, 241, 241);
  width: 200px;
  max-width: 20vh;
  min-width: 120px;
  color: rgb(20, 20, 20);
  padding: 10px 15px;
  position: relative;
  border-radius: 10px;
  &.small {
    width: 150px;
  }
}
.border-orange{
  box-shadow: 0px 0px 11px 6px rgba(238, 255, 0, 0.4);
  cursor: pointer;
}

.border-orange:hover{
  box-shadow: 0px 0px 12px 12px rgba(238, 255, 0, 0.5);
}
.unable{
  transition: all 0.7s ease;
  filter: brightness(60%);
}